var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.noServices)?_c('v-card',[_c('div',{staticClass:"d-flex justify-start my-4"},[_c('v-text-field',{attrs:{"append-icon":_vm.mdiMagnify,"label":"Search for ghosts","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.ghostOrigin))]),_c('v-icon',{attrs:{"right":"","large":""}},[_vm._v(_vm._s(_vm.mdiMenuDown))])],1)]}}],null,false,3485799248)},[_c('v-list',_vm._l((_vm.ghostOrigins),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',{on:{"click":function($event){return _vm.selectGhostOrigin(item)}}},[_vm._v(" "+_vm._s(item)+" ")])],1)}),1)],1)],1),(_vm.noSearchResult && !_vm.noGhosts && !_vm.noServices)?_c('no-match'):_vm._e(),(!_vm.noSearchResult)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.searchFilter,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":""},on:{"page-count":function($event){_vm.pageCount = $event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.allGhosts",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"body-1 text-lowercase"},[_vm._v(" "+_vm._s(item.domain)+_vm._s(_vm.rootDomain)),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.service)+"##"+_vm._s(item.instance)+" ")])])]}},{key:"item.details",fn:function(ref){
var item = ref.item;
return [(item.approve)?_c('div',{staticClass:"float-right"},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"error","text":"","x-small":""},on:{"click":function($event){return _vm.decline(item)}}},[_vm._v("Decline "),_c('v-icon',{attrs:{"x-small":""}},[_vm._v(_vm._s(_vm.mdiClose))])],1),_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"accent","rounded":"","x-small":""},on:{"click":function($event){return _vm.accept(item)}}},[_vm._v("Accept ")])],1):_c('div',[_c('v-btn',{staticClass:"float-right",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.ghostDetails(item)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.mdiChevronRight)+" ")])],1)],1)]}}],null,true)}):_vm._e(),(!_vm.noGhosts && !_vm.allGhostsLengthLessItemsPerPage)?_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }