export const howToCreateMorphicService =
  "https://www.youtube.com/embed/_SO0cs2f5yc";

export const howToCreateMicropage = "https://www.youtube.com/embed/hIQoL5pqdgc";

export const howToLinkTwoServices = "https://www.youtube.com/embed/BKQPXb3so-E";

export const howToUseInlineScript = "https://www.youtube.com/embed/wOUbSMlVT9c";

export const howToCreateGhost = "https://www.youtube.com/embed/uaozW2yVsNA";
