export const rootDomain = ".cioty.com/";

export const systemParameters = [
  "token",
  "username",
  "password",
  "temppassword",
  "objectID",
  "linkedTo",
  "status",
  "mapID",
  "generate",
  "authToken",
  "desc",
  "searchTerms",
  "xml",
  "cmdxml",
  "timeforchannelrequest",
  "premasterscript",
  "masterScript",
  "webjs",
  "links",
  "quantity",
  "instance",
  "description",
  "pictureURL",
  "schemaDescription",
  "cmdSchema",
  "newusername",
  "refdomain",
  "refservice",
  "refobjectID",
  "feature",
  "domainName",
  "active"
];
